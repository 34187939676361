<template>
    <o-data-lookup :data-object="dsLessonsLearned" contextField="IdPath">
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue">
            <slot v-else :target="target" />
        </template>
        <o-column field="ID" width="80"></o-column>
        <o-column field="Name" width="300"></o-column>
        <o-column field="Summary" width="300"></o-column>
        <o-column field="OrgUnit" width="200"></o-column>
    </o-data-lookup>
</template>

<script setup>    

const props = defineProps({
    textInput: Boolean,
    is: String,
    textInputValue: String,        
});

const dsLessonsLearned = $getDataObjectById("dsQuality_LessonsLearned");

</script>